import { FormattedMessage } from 'react-intl'
import ArrowheadLeft from '../../../atoms/icons/arrowhead-left-icon'
import ArrowheadRight from '../../../atoms/icons/arrowhead-right-icon'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useCatalogUrl } from '@bluheadless/ui-logic/src/hooks/useCatalogUrl'
import { memo } from 'react'
import { cx } from '@emotion/css'
import {
	BackButton,
	NavSubMenu,
	ParentNavTitle,
	SubContainerNavItem,
	SubNavItem as SubNavItemStyled,
	SubNavLinkStyled,
	SubNavCmsBlock,
} from './multi-drawer-menu.styled'
import { arrayOf, object, func, number } from 'prop-types'
import RichContent from '../../../particles/rich-content'
import { useRouter } from 'next/router'
import { menuItemIsActive, menuHasActiveItems } from '@bluheadless/ui-logic/src/utils/menu'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'

const SubNavItem = ({ active, className, children }) => {
	return (
		<SubNavItemStyled
			className={cx(className, {
				'submenu-item-active': active,
			})}
			component="div"
		>
			{children}
		</SubNavItemStyled>
	)
}

const SubNavLink = ({ title, url, type, arrowIcon, ...props }) => {
	const productUrl = useCatalogUrl(url)

	let href

	if (url && type === 'product') {
		href = productUrl
	} else {
		href = url
	}

	return (
		<SubNavLinkStyled href={href} {...props}>
			{title}
			{arrowIcon}
		</SubNavLinkStyled>
	)
}

const MultiDrawerSubMenu = memo(
	function MultiDrawerSubMenu({
		activeNode,
		anchor,
		className,
		items,
		parentItemId,
		parentItems,
		setActiveNode,
		toggleMenuId,
		parentCssClasses,
		...props
	}) {
		const { multiDrawerMenu: drawerConfig } = useConfig() // transition should be: overlay (default) || slide
		const transition = props.transition ? props.transition : drawerConfig?.transition
		const activeItem = parentItems.find((parent) => parent.id == parentItemId)
		const { menuItemClick } = useTrackingsActions()
		const { asPath } = useRouter()
		const currentItemActive = menuItemIsActive(activeItem, asPath)
		const active = currentItemActive // for view-all link we need to verify only currentItemActive not children active

		return (
			<NavSubMenu
				anchor={anchor}
				className={cx('NavSubMenu-root', {
					className,
					'nav-open': activeNode.nodeId === parentItemId || activeNode.parentId === parentItemId,
					'nav-moved': transition === 'slide' ? activeNode?.nodeId !== parentItemId : null,
				})}
				{...props}
			>
				<BackButton
					aria-label={<FormattedMessage id="menu_back_button" />}
					variant="text"
					StartIcon={() => <ArrowheadLeft fontSize="middle" />}
					onClick={() => {
						setActiveNode({ nodeId: parentItems.find((parent) => parent.id === toggleMenuId)?.parentId })
					}}
				>
					<FormattedMessage id="menu_back_button" />
				</BackButton>
				<ParentNavTitle
					variant="h6"
					underline="none"
					href={activeItem?.url || null}
					aria-label={activeItem?.title || null}
					className={activeItem?.cssClasses}
				>
					{activeItem?.title ? <RichContent content={activeItem.title} /> : null}
				</ParentNavTitle>
				{items.map((item, index) => {
					const {
						children,
						cssClasses,
						id,
						level,
						parentId,
						target,
						title,
						type,
						path,
						url,
						urlKey,
						entityId,
						identifier,
					} = item
					const hasChildren = children.length > 0
					const currentActive = menuItemIsActive(item, asPath)
					const hasActiveChildren = menuHasActiveItems(item?.children, asPath)
					const active = currentActive || hasActiveChildren

					return type !== 'cms_block' ? (
						<SubContainerNavItem
							className={cx(
								'SubNavContainer-item',
								'SubNavContainer-item-' + type,
								'parent_' + parentCssClasses,
								cssClasses
							)}
							key={id}
						>
							<SubNavItem active={active}>
								<SubNavLink
									type={type}
									tabIndex={index}
									title={<RichContent content={title} />}
									aria-label={title}
									className={cx('submenu-item', 'level-' + level, cssClasses)}
									onClick={(e) => {
										if (hasChildren) {
											setActiveNode({ nodeId: id, parentId: parentId })
										} else {
											menuItemClick({ event: e, entityType: type, entityId, entityName: identifier })
										}
									}}
									url={!hasChildren ? path ?? urlKey ?? url : null}
									target={target}
									underline="none"
									arrowIcon={hasChildren && <ArrowheadRight fontSize="middle" />}
								/>
							</SubNavItem>
							{hasChildren && (
								<MultiDrawerSubMenu
									items={children}
									activeNode={activeNode}
									setActiveNode={setActiveNode}
									parentItemId={id}
									parentItems={items}
									anchor={anchor}
									toggleMenuId={activeNode.nodeId}
								/>
							)}
						</SubContainerNavItem>
					) : null
				})}

				{(activeItem?.url || activeItem?.path || activeItem?.urlKey) && activeItem?.children.length > 0 ? (
					<SubNavItem active={active}>
						<SubNavLink
							type={activeItem.type}
							title={<FormattedMessage id="menu_view_all" />}
							aria-label={<FormattedMessage id="menu_view_all" />}
							className={cx('submenu-item-view-all')}
							url={activeItem?.path ?? activeItem?.urlKey ?? activeItem?.url}
							underline="none"
							variant="body1"
							onClick={(e) => {
								menuItemClick({
									event: e,
									entityType: activeItem.type,
									entityId: activeItem.entityId,
									entityName: activeItem.identifier,
								})
							}}
						/>
					</SubNavItem>
				) : null}

				<SubContainerNavItem className={cx('SubNavContainer-item', 'SubNavContainer-item-cms_block')}>
					{items.map((item) => {
						const { cssClasses, id, level, type, content } = item

						return type == 'cms_block' ? (
							<SubNavCmsBlock key={id} className={cx('submenu-item', 'level-' + level, cssClasses)}>
								<RichContent content={content} />
							</SubNavCmsBlock>
						) : null
					})}
				</SubContainerNavItem>
			</NavSubMenu>
		)
	},
	(prevProps, nextProps) => {
		return (
			prevProps.toggleMenuId == nextProps.toggleMenuId &&
			nextProps.activeNode.nodeId &&
			prevProps.parentItemId &&
			nextProps.activeNode.nodeId !== prevProps.parentItemId &&
			nextProps.activeNode.parentId !== prevProps.parentItemId
		)
	}
)

MultiDrawerSubMenu.propTypes = {
	items: arrayOf(object).isRequired,
	parentItems: arrayOf(object),
	activeNode: object.isRequired,
	setActiveNode: func.isRequired,
	parentItemId: number.isRequired,
}

export default MultiDrawerSubMenu
